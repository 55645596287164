import { Button, Col, Form, Row, Switch } from 'antd';
import React, { useEffect } from 'react';

import CardCollapse from '../../../../components/CardCollapse';
import { useApi } from '../../../../hooks/useApi';
import { usePermission } from '../../../../hooks/usePermissions';
import { useQuery } from "react-query";
import api from "../../../../services/api";

type IProps = {
  loading: boolean;
  id_usuario: string;
  configuracao?: any;
};

const CardConfiguration: React.FC<IProps> = ({
  id_usuario,
  configuracao,
  loading,
}: IProps) => {
  const [form] = Form.useForm();
  const [formBlockEstablishmentPaymentAutomatic] = Form.useForm();

  const { fetchRequest, loading: fetchLoading } = useApi();
  const {
    fetchRequest: fetchRequestBlockEstablishmentPaymentAutomatic,
    loading: fetchLoadingBlockEstablishmentPaymentAutomatic,
  } = useApi();
  const { userPermissions } = usePermission();

  useQuery({
    queryKey: ['establishment-info', id_usuario],
    queryFn: async () => {
      const response = await api.get<{ run_risk_analysis: boolean }>(`/backoffice/establishment/${id_usuario}`)

      return response.data
    },
    onSuccess({ run_risk_analysis }) {
      form.setFieldsValue({ run_risk_analysis })
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 0
  })

  async function updateRiskAnalysFlag(data: any) {
    fetchRequest({
      method: 'patch',
      url: `/backoffice/establishment/${id_usuario}`,
      data,
      messages: {
        loading: 'Atualizando configurações, aguarde...',
        success: `Análise de Risco ${data.run_risk_analysis ? 'ativado' : 'desativado'}!`,
      },
    });
  }

  async function updateBlockEstablishmentPaymentAutomatic(data: any) {
    if (data.is_blocked_payment_automatic) {
      fetchRequestBlockEstablishmentPaymentAutomatic({
        method: 'post',
        url: `/bloquear/estabelecimento`,
        data: {
          id_estabelecimento: id_usuario,
        },
        messages: {
          error: 'Erro ao atualizar configurações, tente novamente mais tarde!',
          loading: 'Atualizando configurações, aguarde...',
          success: 'Configurações atualizadas com sucesso!',
        },
      });
      return;
    }

    fetchRequestBlockEstablishmentPaymentAutomatic({
      method: 'delete',
      url: `/bloquear/estabelecimento/${id_usuario}`,
      messages: {
        error: 'Erro ao atualizar configurações, tente novamente mais tarde!',
        loading: 'Atualizando configurações, aguarde...',
        success: 'Configurações atualizadas com sucesso!',
      },
    });
    return;
  }

  useEffect(() => {
    form.setFieldsValue(configuracao);
    formBlockEstablishmentPaymentAutomatic.setFieldsValue(configuracao);
  }, [configuracao]);

  return (
    <CardCollapse title="Configurações" loading={loading}>
      <Row style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Col>
          <Form
            layout="horizontal"
            form={form}
            onFinish={updateRiskAnalysFlag}
          >
            <Form.Item
              label="Utiliza Análise de Risco"
              name="run_risk_analysis"
              valuePropName="checked"
            >
              <Switch loading={fetchLoading} />
            </Form.Item>

            {userPermissions.TAXA_ADICIONAR && userPermissions.TAXA_REMOVER ? (
              <>
                <br />

                <Button type="primary" htmlType="submit" loading={fetchLoading}>
                  Salvar
                </Button>
              </>
            ) : null}
          </Form>
        </Col>
        <Col>
          <Form
            layout="horizontal"
            form={formBlockEstablishmentPaymentAutomatic}
            onFinish={updateBlockEstablishmentPaymentAutomatic}
          >
            <Form.Item
              label="Bloqueia liquidação automática (POS)"
              name="is_blocked_payment_automatic"
              valuePropName="checked"
            >
              <Switch
                loading={fetchLoadingBlockEstablishmentPaymentAutomatic}
              />
            </Form.Item>

            {userPermissions.TAXA_ADICIONAR && userPermissions.TAXA_REMOVER ? (
              <>
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  loading={fetchLoadingBlockEstablishmentPaymentAutomatic}
                >
                  Salvar
                </Button>
              </>
            ) : null}
          </Form>
        </Col>
      </Row>
    </CardCollapse>
  );
};

export default CardConfiguration;
