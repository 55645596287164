import { Button, Col, Form, Input, message, Modal, Row, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import UserName from '../../../components/User/Name';
import { CreateApiClient } from "./types";
import Pagina from "../../../types/Pagina";
import { setQueryParams } from "../../../utils/UrlQuery";
import { uuid_pattern } from "../../../utils/pattern";
import { useClients, useCreateApiClient, useValidateUser } from "../hooks";

const { Paragraph } = Typography;

const ClienteApiPesquisar: React.FC = () => {
  const [apiClientCreation, setApiClientCreation] = useState<string | undefined>();
  const [formApiClientCreation] = Form.useForm();

  const defaultFilters = { page: 1, page_size: 25, };
  const [filters, setFilters] = useState<{ page?: number; page_size?: number }>(defaultFilters);
  const [pagination, setPagination] = useState<Pagina<any[]>>({ page_size: 25, page: 1, total: 0 });
  const [userValidationData, setUserValidationData] = useState<CreateApiClient | undefined>(undefined);

  useEffect(() => {
    setPagination(prev => ({ ...prev, ...filters }));
  }, [filters]);

  const { isLoading, data: clients } = useClients(filters);
  const { data: user, isLoading: isUserLoading, isError: isUserError } = useValidateUser(userValidationData);
  const { mutate: createApiCliente } = useCreateApiClient({
    onSuccess: () => {
      handleCloseModal();
    }
  });

  useEffect(() => {
    if (userValidationData && !isUserLoading && !isUserError && user) {
      createApiCliente(userValidationData);
      setUserValidationData(undefined);
    } else if (userValidationData && !isUserLoading && isUserError) {
      message.error('Erro ao validar o usuário, tente novamente!');
      setUserValidationData(undefined);
    }
  }, [user, isUserLoading, isUserError, userValidationData, createApiCliente]);

  function handleUpdatePagination(page: number, page_size?: number) {
    const queryParams = { ...filters, page, page_size };
    setQueryParams(queryParams);
    setFilters(queryParams);
  }

  const handleCreateApiClient = (data: CreateApiClient) => {
    setUserValidationData(data);
  };

  const handleCloseModal = () => {
    setApiClientCreation(undefined);
    formApiClientCreation.resetFields();
  };

  const columns = [
    {
      title: '#',
      key: 'id_api_client',
      dataIndex: 'id_api_client',
      render: (item: string) => (
        <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
      ),
    },
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Estabelecimento',
      key: 'establishment_id',
      dataIndex: 'establishment_id',
      render: (id: string) => <UserName id_user={id} />,
    },
    {
      title: 'Abrir',
      key: 'id_api_client',
      dataIndex: 'id_api_client',
      width: 80,
      render: (item: string) => (
        <Link className="ant-btn ant-btn-primary" to={`/cliente-api/${item}`}>
          <MdOpenInNew />
        </Link>
      ),
    },
  ]

  return (
    <>
      <PageHeader title="Clientes da API" breadcrumb={['Clientes API']} />

      <Button
        onClick={() => setApiClientCreation('Api-Client')}
        type="primary"
      >
        Criar Cliente API
      </Button>

      <br />
      <br />

      <Table
        columns={columns}
        dataSource={clients?.data}
        loading={isLoading}
        size="small"
        pagination={{
          total: pagination?.total,
          current: pagination?.page,
          pageSize: pagination?.page_size,
          onChange: handleUpdatePagination,
        }}
      />

      <Modal
        visible={!!apiClientCreation}
        title="Criação de Cliente API"
        footer={false}
        onCancel={handleCloseModal}
        centered
      >
        <Form
          form={formApiClientCreation}
          layout="vertical"
          onFinish={handleCreateApiClient}
        >
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Preencha o nome para continuar.',
                    transform(value) {
                      return value.trim()
                    },
                  },
                ]}
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Descrição"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Preencha a descrição para continuar.',
                    transform(value) {
                      return value.trim()
                    },
                  },
                ]}
              >
                <Input maxLength={255} />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Estabelecimento"
                name="id_establishment"
                rules={[
                  {
                    required: true,
                    pattern: uuid_pattern,
                    message: 'Deve ser um ID válido!',
                    transform(value) {
                      return value.trim()
                    },
                  },
                ]}
              >
                <Input maxLength={36} />
              </Form.Item>
            </Col>
            <Col md={12} />
            <Col md={24}>
              <br />
              <Button htmlType="submit" type="primary" block>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ClienteApiPesquisar;