import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';
import { IState } from '../../../store';
import { IPerfil } from '../../../store/modules/user/reducer';
import EnumPerfil from '../../../types/enum/Perfis';

import CardAnotacao from './CardAnotacao';
import CardPermissoes from './CardPermissoes';
import CardVinculos from './CardVinculos';
import CardPOS from './CardPOS';
import CardPerfil from './CardPerfil';
import CardTipoLiquidacao from './CardTipoLiquidacao';
import CardGeral from './CardGeral';
import CardWhitelabel from './CardWhitelabel';
import CardTaxa from './CardTaxa';
import Dashboard from './Dashboard';
import { usePermission } from '../../../hooks/usePermissions';
import CardConfiguration from './CardConfiguration';
import CardParceiros from './CardParceiros';
import CardQueryRule from './CardQueryRule';
import CardTerminal from "./CardTerminal";

const default_message = 'Esse campo é obrigatório';

interface IUsuario {
  usuario?: any;
  dado_bancario?: any;
  whitelabel?: any;
  configuracao?: any;
  permissoes?: any[];
  pos?: any[];
  perfis?: any[];
  tipo_liquidacao?: any[];
  anotacoes?: any[];
  dashboard: {
    billingRevenue: number;
    commission: number;
  };
}

const UsuarioInfo: React.FC = () => {
  const usuario_perfis = useSelector<IState, IPerfil[]>(
    state => state.user.perfis,
  );
  const { userPermissions } = usePermission();

  const { id } = useParams<{ id: string }>();

  const [usuario, setUsuario] = useState<IUsuario>({} as IUsuario);
  const [loading, setLoading] = useState(true);

  const is_representante = useMemo(() => {
    if (
      usuario.permissoes?.find(
        e =>
          e.id_responsabilidade === EnumResponsabilidade.REPRESENTANTE &&
          e.status_tipo === 'success',
      )
    ) {
      return true;
    }

    return false;
  }, [usuario]);

  const user_is_master = useMemo(() => {
    return usuario_perfis.some(item => item.id_perfil === EnumPerfil.MASTER);
  }, [usuario_perfis]);

  const user_is_totem = useMemo(() => {
    return usuario.perfis?.some(item => item.id_perfil === EnumPerfil.TOTEM);
  }, [usuario]);

  const is_estabelecimento = useMemo(() => {
    if (
      usuario.permissoes?.find(
        e => e.id_responsabilidade === EnumResponsabilidade.ESTABELECIMENTO,
      )
    ) {
      return true;
    }

    return false;
  }, [usuario]);

  const is_estabelecimento_aproved = useMemo(() => {
    if (
      usuario.permissoes?.find(
        e =>
          e.id_responsabilidade === EnumResponsabilidade.ESTABELECIMENTO &&
          e.status_tipo === 'success',
      )
    ) {
      return true;
    }

    return false;
  }, [usuario]);

  const is_incentivador = useMemo(() => {
    if (
      usuario.permissoes?.find(
        e => e.id_responsabilidade === EnumResponsabilidade.INCENTIVADOR,
      )
    ) {
      return true;
    }

    return false;
  }, [usuario]);

  useEffect(() => {
    setLoading(true);

    fetchUserData().then(() => {
      setLoading(false);
    });
  }, [id]);

  function fetchUserData() {
    return fetchApi<IUsuario>({
      url: `/usuario/${id}`,
      method: 'get',
      onSuccess: data => {
        const mask = 'dd/MM/yyyy HH:mm:ss';

        const permissoes = data?.permissoes?.map(item => ({
          ...item,
          criado_em: format(parseISO(item.criado_em), mask),
          removido_em: item.removido_em
            ? format(parseISO(item.removido_em), mask)
            : undefined,
        }));
        const pos = data?.pos?.map(item => ({
          ...item,
          criado_em: format(parseISO(item.criado_em), mask),
        }));
        const perfis = data?.perfis?.map(item => ({
          ...item,
          criado_em: format(parseISO(item.criado_em), mask),
        }));

        setUsuario({
          ...data,
          permissoes,
          pos,
          perfis,
        });
      },
    });
  }

  return (
    <>
      <PageHeader
        title="Informações do Usuário"
        breadcrumb={['Usuário', 'Informações']}
      >
        <Link to={`/usuario/${id}/editar`}>
          <Button type="primary">Editar</Button>
        </Link>
      </PageHeader>

      <Row gutter={16}>
        <Col md={8} xs={24}>
          <CardGeral
            loading={loading}
            info={usuario?.usuario}
            permissoes={usuario.permissoes || []}
            perfis={usuario.perfis || []}
          />

          <CardAnotacao
            id_usuario={id}
            anotacoes={usuario.anotacoes || []}
            onAddAnotacao={() => fetchUserData()}
            loading={loading}
          />
        </Col>
        <Col md={16} xs={24}>
          <Dashboard
            commission={usuario?.dashboard?.commission || 0}
            billingRevenue={usuario?.dashboard?.billingRevenue || 0}
            loading={loading}
          />

          <CardPermissoes
            default_message={default_message}
            id_usuario={id}
            loading={loading}
            permissoes={usuario.permissoes || []}
            onAddPermissao={() => fetchUserData()}
            onRemovePermissao={() => fetchUserData()}
          />

          <CardPerfil
            default_message={default_message}
            id_usuario={id}
            loading={loading}
            user_is_master={user_is_master}
            usuario_perfis={usuario.perfis || []}
            onAddPerfil={() => fetchUserData()}
            onRemovePerfil={() => fetchUserData()}
          />

          <CardTipoLiquidacao
            default_message={default_message}
            id_usuario={id}
            is_estabelecimento={is_estabelecimento}
          />

          {is_estabelecimento_aproved ? (
            <CardQueryRule
              id_usuario={id}
              default_message={default_message}
            />
          ) : null}

          <CardVinculos
            id_usuario={id}
            loading={loading}
            is_estabelecimento={is_estabelecimento}
            is_representante={is_representante}
          />

          {is_incentivador && user_is_totem ? (
            <CardParceiros
              default_message={default_message}
              loading={loading}
              user_is_totem={user_is_totem || false}
            />
          ) : null}

          {is_estabelecimento && userPermissions.TAXA_VISUALIZAR ? (
            <CardConfiguration
              id_usuario={id}
              loading={loading}
              configuracao={usuario?.configuracao}
            />
          ) : null}

          {is_estabelecimento ? (
            <CardPOS
              pos={usuario.pos || []}
              default_message={default_message}
              id_usuario={id}
              loading={loading}
              onAddPOS={() => fetchUserData()}
              onRemovePOS={() => fetchUserData()}
            />
          ) : null}

          {is_estabelecimento ? (
            <CardTerminal
              userId={id}
            />
          ) : null}

          <CardWhitelabel
            id_usuario={id}
            whitelabel={usuario.whitelabel}
            perfis={usuario.perfis}
          />

          {is_estabelecimento && userPermissions.TAXA_VISUALIZAR ? (
            <CardTaxa id_usuario={id} />
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default UsuarioInfo;
