import { Divider, Result, Table as TableAntd } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format, parseISO } from 'date-fns';
import { porcentagemMask } from 'masks-br';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import CardCollapse from '../../../../components/CardCollapse';
import { usePermission } from '../../../../hooks/usePermissions';
import { fetchApi } from '../../../../services/api';
import { findTagByTipo } from '../../../../utils/findTagColorByTipo';

type IState = {
  loading: boolean;
  id_usuario: string;
  is_estabelecimento: boolean;
  is_representante: boolean;
};

const CardVinculos: React.FC<IState> = ({
  loading,
  id_usuario,
  is_estabelecimento,
  is_representante,
}: IState) => {
  const { userPermissions } = usePermission();

  const [vinculosComEstabelecimento, setVinculosComEstabelecimento] = useState<
    any[]
  >([]);
  const [vinculosDeEstabelecimento, setVinculosDeEstabelecimento] = useState<
    any[]
  >([]);

  useEffect(() => {
    Promise.all([
      fetchVinculosComEstabelecimento(),
      fetchVinculosDeEstabelecimento(),
    ]);
  }, []);

  function fetchVinculosComEstabelecimento() {
    return fetchApi({
      url: `/usuario/${id_usuario}/vinculo/usuario`,
      method: 'get',
      onSuccess: data => {
        const list = data?.map((item: any) => ({
          ...item,
          criado_em: format(parseISO(item.criado_em), 'dd/MM/yyyy HH:mm:ss'),
          removido_em: item.removido_em
            ? format(parseISO(item.removido_em), 'dd/MM/yyyy HH:mm:ss')
            : '-',
        }));

        setVinculosComEstabelecimento(list);
      },
    });
  }

  function fetchVinculosDeEstabelecimento() {
    return fetchApi({
      url: `/usuario/${id_usuario}/vinculo/estabelecimento`,
      method: 'get',
      onSuccess: data => {
        const list = data?.ativo?.map((item: any) => ({
          ...item,
          criado_em: format(parseISO(item.criado_em), 'dd/MM/yyyy HH:mm:ss'),
          removido_em: item.removido_em
            ? format(parseISO(item.removido_em), 'dd/MM/yyyy HH:mm:ss')
            : '-',
        }));

        setVinculosDeEstabelecimento(list);
      },
    });
  }

  const columns_vinculos_com_estabelecimento = [
    {
      title: 'Estabelecimento',
      dataIndex: 'usuario_para_nome',
      key: 'usuario_para_nome',
      width: '20%',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario_para}`}>{item}</Link>
      ),
    },
    {
      title: 'Permissão',
      dataIndex: 'responsabilidade',
      key: 'responsabilidade',
      width: '20%',
      render: (item: string, data: any) => (
        <Link to={`/responsabilidade/${btoa(data.id_responsabilidade)}`}>
          {item}
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_nome',
      key: 'status_nome',
      width: '20%',
      render: (item: string, data: any) =>
        findTagByTipo(item, data.status_tipo),
    },
    {
      title: 'Porcentagem',
      dataIndex: 'comissao',
      key: 'comissao',
      width: '20%',
      render: (item: string) => porcentagemMask(item),
    },
    {
      title: 'Criado Em',
      dataIndex: 'criado_em',
      key: 'criado_em',
      width: '20%',
    },
  ];

  const columns_vinculos_de_estabelecimento = [
    {
      title: 'Usuário',
      dataIndex: 'usuario_de_nome',
      key: 'usuario_de_nome',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario_de}`}>{item}</Link>
      ),
    },
    {
      title: 'Permissão',
      dataIndex: 'responsabilidade',
      key: 'responsabilidade',
      width: '20%',
      render: (item: string, data: any) => (
        <Link to={`/responsabilidade/${btoa(data.id_responsabilidade)}`}>
          {item}
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_nome',
      key: 'status_nome',
      render: (item: string, data: any) =>
        findTagByTipo(item, data.status_tipo),
    },
    {
      title: 'Porcentagem',
      dataIndex: 'comissao',
      key: 'comissao',
      width: '20%',
      render: (item: string) => porcentagemMask(item),
    },
    {
      title: 'Criado Em',
      dataIndex: 'criado_em',
      key: 'criado_em',
      width: '20%',
    },
  ];

  return (
    <>
      <CardCollapse loading={loading} title="Vínculos" start>
        {userPermissions.USUARIO_VISUALIZAR_VINCULOS &&
        (is_estabelecimento || is_representante) ? (
          <Link to={`/usuario/${id_usuario}/vinculo`}>Abrir vínculos</Link>
        ) : null}

        <Divider orientation="left">Com Estabelecimento</Divider>
        <Text type="secondary">
          Vínculos que outros usuários possui com esse usuário
        </Text>
        {vinculosComEstabelecimento.length ? (
          <TableAntd
            columns={columns_vinculos_com_estabelecimento}
            dataSource={vinculosComEstabelecimento}
            pagination={false}
          />
        ) : (
          <Result extra="Usuário não tem nenhum vínculo com estabelecimento" />
        )}

        <Divider orientation="left">De Estabelecimento</Divider>
        <Text type="secondary">
          Vínculos que esse usuário possui com os outros usuários
        </Text>
        {vinculosDeEstabelecimento.length ? (
          <TableAntd
            columns={columns_vinculos_de_estabelecimento}
            dataSource={vinculosDeEstabelecimento}
            pagination={false}
          />
        ) : (
          <Result extra="Usuário não tem nenhum vínculo de estabelecimento" />
        )}
      </CardCollapse>
    </>
  );
};

export default CardVinculos;
