import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageHeader from '../../../components/PageHeader';
import { IState } from '../../../store';
import { updateResponsabilidade } from '../../../store/modules/auth/actions';
import { IResponsabilidade } from '../../../store/modules/user/reducer';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';

import { Container, Card } from './styles';

const AlterarResponsabilidade: React.FC = () => {
  const responsabilidades = useSelector<IState, IResponsabilidade[]>(state => state.user.responsabilidades);
  const responsabilidade_selecionada = useSelector<IState, EnumResponsabilidade | undefined>(state => state.auth.responsabilidade);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleUpdateResponsabilidade(id_responsabilidade: number) {
    dispatch(updateResponsabilidade(id_responsabilidade));

    history.push('/dashboard');
  }

  return (
    <>
      <PageHeader title="Alterar Responsabilidade" />

      <p>Selecione a responsabilidade que você deseja visualizar</p>

      <Container>
        {responsabilidades.map(item => (
          <Card
            selected={responsabilidade_selecionada === item.id_responsabilidade}
            onClick={() => handleUpdateResponsabilidade(item.id_responsabilidade)}
          >
            {item.nome}
          </Card>
        ))}
      </Container>
    </>
  );
}

export default AlterarResponsabilidade;